export default {
  background: {
    color: {
      value: '#121212',
    },
  },
  fpsLimit: 30,
  particles: {
    color: {
      value: '#58cbf2',
    },
    links: {
      color: '#58cbf2',
      distance: 400,
      enable: true,
      opacity: 0.1,
      width: 1,
    },
    collisions: {
      enable: true,
    },
    move: {
      direction: 'none',
      enable: true,
      outMode: 'bounce',
      random: false,
      speed: 1,
      straight: false,
    },
    number: {
      density: {
        enable: true,
        value_area: 800,
      },
      value: 40,
    },
    opacity: {
      value: 0.2,
    },
    shape: {
      type: 'circle',
    },
    size: {
      random: true,
      value: 3,
    },
  },
  detectRetina: true,
};
