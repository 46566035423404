import React from 'react';
import TsParticles from 'react-tsparticles';
import styled from 'styled-components';
import particlesConfig from './particlesConfig';

const TsParticlesStyled = styled(TsParticles)`
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 60%;
`;

const ParticlesOverlay = styled.div`
  background: none;
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: -1;
`;

export const Particles: React.FC = () => {
  return (
    <>
      <TsParticlesStyled options={particlesConfig} />
      <ParticlesOverlay />
    </>
  );
};
